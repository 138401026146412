import { VStack, useColorModeValue, Heading, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Card, CardBody, Center } from "@chakra-ui/react";
import React from "react";
import { MdEmail, MdPhone } from "react-icons/md";
import CopyInfoRow from "./CopyInfoRow";

function ContactInfo() {
    const button = useColorModeValue('tertiary.main', 'tertiary.100');

    return (
        <VStack py={'2'} px={'4'}>
            <Heading as={'h2'} size={'lg'}>Email:</Heading>
            <Card bg={useColorModeValue('tertiary.500', 'tertiary.200')}>
                <CardBody>
                    <Center>
                        <CopyInfoRow icon={MdEmail} info={'at-tuition@outlook.com'} type={'Email'} />
                    </Center>
                </CardBody>
            </Card>

            <Accordion allowMultiple w={['xs', 'sm', 'md', 'lg']} rounded="lg">
                <AccordionItem>
                    <AccordionButton
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={4}
                    _hover={{
                        textDecoration: 'none',
                        transform: 'translateY(-2px)',
                        boxShadow: 'xl',
                        textColor: 'black',
                        bg: button
                    }}
                    rounded={'md'}>
                        <Heading size={'md'}>
                            Tasdiq Dewan
                        </Heading>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <CopyInfoRow icon={MdEmail} info={'tasdiqd@gmail.com'} type={'Email'} />

                        <CopyInfoRow icon={MdPhone}  info={'07466 448234'} type={'Phone Number'} />
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionButton
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={4}
                    _hover={{
                        textDecoration: 'none',
                        transform: 'translateY(-2px)',
                        boxShadow: 'xl',
                        textColor: 'black',
                        bg: button
                    }}
                    rounded={'md'}>
                        <Heading size={'md'}>
                            Abdul-Jabbar Malik
                        </Heading>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <CopyInfoRow info={'jabbidmalik@hotmail.co.uk'} icon={MdEmail} type={'Email'} />

                        <CopyInfoRow info={'07856 058711'} icon={MdPhone} type={'Phone Number'} />
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </VStack>
    );
}

export default ContactInfo;