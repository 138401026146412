import { HStack, Icon, useColorModeValue, Text, useClipboard, Tooltip, IconButton, Center } from "@chakra-ui/react";
import React from "react";
import { MdEmail, MdContentCopy } from "react-icons/md";

function CopyInfoRow(props) {
    const { hasCopied, onCopy } = useClipboard(props.info);

    return(
        <HStack>
            <Icon as={props.icon} boxSize={'8'} rounded={'md'}/>
            <Text fontSize={'lg'}>{props.info}</Text>
            <Tooltip label={hasCopied ? `${props.type} Copied!` : `Copy ${props.type}`}
                closeOnClick={false}
                hasArrow>
                <IconButton aria-label="copy"
                    variant={'ghost'}
                    size={'md'}
                    icon={<MdContentCopy/>}
                    isRound
                    _hover={{
                        bg: 'primary.600',
                        color: useColorModeValue('white', 'gray.700'),
                    }}
                    onClick={onCopy}/>
            </Tooltip>
        </HStack>
    );
}

export default CopyInfoRow;