import { Card, CardBody, CardHeader, Center, Heading, Stack, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import ContactForm from "../Components/ContactForm";
import ContactInfo from "../Components/ContactInfo";

function ContactUs() {
    return(
        <Center className="contact-us" py={'4'}>
        <Card bg={useColorModeValue('white', 'primary.800')} 
            direction={'column'}
            w={'fit-content'}
            align={'center'}>
            <CardHeader>
                <Heading textColor={useColorModeValue('primary.main', 'white')}>Get in touch!</Heading>
            </CardHeader>
            <CardBody>
            <Stack direction={['column', 'column', 'row']}>
                <ContactInfo />
                <ContactForm />
            </Stack>  
            </CardBody>
        </Card>
        </Center>
    );
}

export default ContactUs;