import { Box, Image, Card, CardBody, Heading, ListItem, UnorderedList, useColorModeValue, Divider, Text, 
    Accordion, AccordionButton, AccordionItem, AccordionPanel, AccordionIcon, List, ListIcon, Center, Stack, CardHeader, VStack, } from "@chakra-ui/react";
import React from "react";
import { Subject } from "../types/Subject";
import { MdCheckCircle } from 'react-icons/md';
import parse from 'html-react-parser';

function SubjectPage(subject: Subject) {
    const card = useColorModeValue('white', 'primary.800');
    const divider = useColorModeValue('secondary.main', 'secondary.100');
    const button = useColorModeValue('tertiary.main', 'tertiary.100');
    const heading = useColorModeValue('primary.main', 'white');
    const topicCard = useColorModeValue('primary.main', 'tertiary.700')
    const gcse: string[] | undefined = subject.topics.get("GCSE");
    const aLevel: string[] | undefined = subject.topics.get("A Level");

    return (
        <Box h={'full'} py={'4'} w={'100%'} px={'4'}>
           <Card w={'100%'} align={'center'} bg={card} variant={'filled'}>
                <Heading size={['2xl']} py={'4'} textColor={heading}>
                    {subject.name}
                </Heading>
                <VStack>
                    <Image 
                    px={'4'} 
                    py={'4'} 
                    maxW={{ base: '100%', sm:'300px', md: '500px' }} 
                    fallbackSrc='https://via.placeholder.com/200' 
                    src={subject.img_src} alt={subject.name+": "+subject.img_caption} 
                    objectFit={"cover"}
                    borderRadius='3xl'/>
                    {/* <Text align={'center'} fontSize={'2xs'}>{subject.img_caption}</Text> */}
                    {parse(subject.img_caption)}
                </VStack>
                <CardBody>
                    <Text py={'4'} align='start' fontSize={'lg'} whiteSpace={'pre-wrap'}>
                        {subject.description}
                    </Text>
                    <Divider borderColor={divider}/>
                    <Heading textAlign={'center'} pt={'4'} size='lg'>Topics we teach!</Heading>
                    <Center>
                        <Stack direction={['column', 'column', 'row']} py={4}>
                            {gcse !== undefined && 
                                <Card bg={topicCard} variant={'filled'} align={'center'} textColor={'white'}>
                                    <CardHeader textDecoration={'underline'}><Heading size={'md'}>GCSE</Heading></CardHeader>
                                    <CardBody>
                                        <List spacing={'3'}>
                                        {
                                            gcse.map((topic: string, key: number) => 
                                            (<ListItem textAlign={'start'} fontSize={'lg'}>
                                                <ListIcon as={MdCheckCircle} color={'green'}/>
                                                {topic}
                                            </ListItem>))
                                        }
                                        </List>
                                    </CardBody>
                                </Card>
                            }
                            {aLevel !== undefined && 
                                <Card bg={topicCard} variant={'filled'} align={'center'} textColor={'white'}>
                                    <CardHeader textDecoration={'underline'}><Heading size={'md'}>A Level</Heading></CardHeader>
                                    <CardBody>
                                        <List spacing={'3'}>
                                        {
                                            aLevel.map((topic: string, key: number) => 
                                            (<ListItem textAlign={'start'} fontSize={'lg'}>
                                                <ListIcon as={MdCheckCircle} color={'green'}/>
                                                {topic}
                                            </ListItem>))
                                        }
                                        </List>
                                    </CardBody>
                                </Card>
                            }
                        </Stack>
                    </Center>
                    <Divider borderColor={divider}/>
                    <Center>
                        <Accordion py={'2'} allowMultiple width={['xs', 'sm', 'md', 'lg', '3xl']} rounded="lg">
                            {<AccordionItem>
                                <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={4}
                                _hover={{
                                    textDecoration: 'none',
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'xl',
                                    textColor: 'black',
                                    bg: button
                                }}
                                rounded={'md'}>
                                    <Heading size='md'>Qualifications and Exam Boards</Heading>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                <UnorderedList>
                                    {
                                        subject.school_level.map((qual: string, key: number) => 
                                        (<ListItem fontSize={'lg'} textAlign={'start'}>{qual}</ListItem>))
                                    }
                                </UnorderedList>
                                </AccordionPanel>
                            </AccordionItem>}
                        </Accordion>  
                    </Center>     
                </CardBody>
           </Card>
        </Box>
    );
}

export default SubjectPage;