import { Box, Text, Heading, Image, Card, CardBody, UnorderedList, ListItem, Center, Wrap, Divider, VStack, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import type { Profile } from "../types/Profile"
import LinkedinButton from "./LinkedInButton";

function ProfileSection(profile: Profile) {
    const card = useColorModeValue('white', 'primary.800');
    const divider = useColorModeValue('secondary.main', 'secondary.100');
    const heading = useColorModeValue('primary.main', 'white');

    return(
        <Box maxW={'100%'}>
            <Card size='lg' direction={[ 'column', 'column', 'row']} variant='filled' bg={card}>
                <Box>
                    <Image 
                    px={'4'} 
                    py={'4'} 
                    maxW={{ base: '100%', sm:'300px',  }} 
                    fallbackSrc='https://via.placeholder.com/200' 
                    src={profile.img_src} alt={profile.name} 
                    objectFit={"cover"}
                    borderRadius='3xl'/>
                </Box>
                <CardBody>
                    <Wrap spacing='4'>
                        <Heading textColor={heading} size='xl'>About {profile.name}</Heading>
                        <Divider borderColor={divider}/>
                        <Text align='start' fontSize={'lg'} whiteSpace={'pre-wrap'}>
                            {profile.description}
                        </Text>
                        <Divider borderColor={divider}/>
                        <VStack align={'start'}>
                            <Heading size='lg'>Experience</Heading>
                            <Center>
                                <UnorderedList>
                                    {
                                        profile.experience.map((experience: string, key: number) => 
                                            (<ListItem textAlign={'start'}>{experience}</ListItem>))
                                    }
                                </UnorderedList>
                            </Center>
                        </VStack>
                        <Divider borderColor={divider}/>
                        <LinkedinButton url={profile.linkedIn} />
                    </Wrap>
                </CardBody>
            </Card>
        </Box>
    );
}

export default ProfileSection;
