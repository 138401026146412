import { Subject, SubjectMap } from '../types/Subject'

const COMPUTER_SCIENCE: Subject = {
    name: "Computer Science",
    description: `At AT Tuition, we not only teach the required knowledge and skills for the school curriculum but also the fundamental skills and mentality for further education and profressional careers in Computer Science
    \nWe cover both GCSE and A Level Computer Science, teaching all the core areas of the specification and working through practical exercises and past exam questions. A large portion of our time will be spent bolstering our students' core programming skills primarily through demos and exercises with C#. However, we will also address specific topics or languages of interest to our students in order to help with their current studies at school or wider curiousity.`,
    topics: new Map([
        ["GCSE", [
            "Fundamentals of Procedural Programming",
            "Design, Problem Solving, and Debugging",
            "Basic Computer Architecture",
            "Data Types and Representation",
            "Algorithms",
            "Logical Operations",
            "Networking and Security",
            "Databases",
            "Ethics and Impact on Society"
        ]],
        ["A Level", [
                "Fundamentals of Procedural Programming",
                "Design, Problem Solving, and Debugging",
                "Basic Computer Architecture",
                "Data Types and Representation",
                "Theory of Computational Abstraction and Automation",
                "Algorithms",
                "Logical Operations",
                "Networking and Security",
                "Databases",
                "Ethics and Impact on Society"
        ]]
    ]),
    school_level: [
            "GCSE - AQA, Eduqas, OCR, Pearson Edexcel",
            "A Level - AQA, Eduqas, OCR"
        ],
    img_src: "../images/james-harrison-programming-unsplash.jpg",
    img_caption: `<p>Photo by <a href="https://unsplash.com/@jstrippa?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">James Harrison</a> on <a href="https://unsplash.com/photos/black-laptop-computer-turned-on-on-table-vpOeXr5wmR4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></p>`
};

const MATHS: Subject = {
    name: "Mathematics",
    description: `We cover GCSE and A Level Maths, teaching all the core areas of the specification and working through exam questions.
    \nWe assess students' knowledge and strenghts to determine how to utilise each students strengths and skills in learning new concepts and reinfornce areas they may be weak in.`,
    topics: new Map([
        ["GCSE", [
            "Arithmetics",
            "Basic Algebra",
            "Fractions, Decimals, and Percentages",
            "Powers and Roots",
            "Ratios and Proportions",
            "Probability",
            "Geometry",
            "Measures and Calculations",
            "Statistics"
        ]],
        ["A Level", [
            "Algebraic Equations and Functions",
            "Coordinate Geometry",
            "Calculus",
            "Trigonometry",
            "Exponentials and Logarithms",
            "Sequences and Series",
            "Matrices",
            "Mechanics Kinematics and Forces",
            "Proofs",
            "Hyperbolic Functions"
        ]]
]), 
    school_level: [
            "GCSE - AQA, OCR, Pearson Edexcel",
            "A Levels - AQA, OCR, Pearson Edexcel"
        ],
    img_src: "../images/antoine-dautry-maths-unsplash.jpg",
    img_caption: `<p>Photo by <a href="https://unsplash.com/@antoine1003?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Antoine Dautry</a> on <a href="https://unsplash.com/photos/mathematics-computation-05A-kdOH6Hw?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></p>`
};

const BIOLOGY: Subject = {
    name: "Biology",
    description: `We cover GCSE Biology, teaching all the core areas of the specification and working through exam questions.
    \nWe assess students' knowledge and strenghts to determine how to utilise each students strengths and skills in learning new concepts and reinfornce areas they may be weak in.`,
    topics: new Map([
        ["GCSE", [
            "Cell Structure",
            "Organisation",
            "Infection and Immune Response",
            "Bioenergetics",
            "Homeostasis",
            "Inheritance, Natural Selection, and Evolution",
            "Ecology",
            "Practicals and Data Analytics"
        ]]
    ]),
    school_level: [
            "GCSE - AQA, OCR, Pearson Edexcel"
        ],
    img_src: "../images/national-cancer-institute-L7en7Lb-Ovc-unsplash.jpg",
    img_caption: `<p>Photo by <a href="https://unsplash.com/@nci?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">National Cancer Institute</a> on <a href="https://unsplash.com/photos/purple-cells-L7en7Lb-Ovc?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></p>`,
};

const CHEMISTRY: Subject = {
    name: "Chemistry",
    description: `We cover GCSE Chemistry, teaching all the core areas of the specification and working through exam questions.
    \nWe assess students' knowledge and strenghts to determine how to utilise each students strengths and skills in learning new concepts and reinfornce areas they may be weak in.`,
    topics: new Map([
        ["GCSE", [
            "Atomic Structure and Periodic Table",
            "Bonding Structure",
            "Quantitative Chemistry",
            "Chemical Changes",
            "Energy Changes",
            "Rate of Reaction",
            "Organic Chemistry",
            "Chemical Analysis",
            "Atmosphere",
            "Practicals and Data Analysis"
        ]]
    ]),
    school_level: [
            "GCSE - AQA, OCR, Pearson Edexcel"
        ],
    img_src: "../images/terry-vlisidis-RflgrtzU3Cw-unsplash.jpg",
    img_caption: `<p>Photo by <a href="https://unsplash.com/@vlisidis?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Terry Vlisidis</a> on <a href="https://unsplash.com/photos/blue-white-and-yellow-balloons-RflgrtzU3Cw?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></p>`,
};

const PHYSICS: Subject = {
    name: "Physics",
    description: `We cover GCSE Physics, teaching all the core areas of the specification and working through exam questions.
    \nWe assess students' knowledge and strenghts to determine how to utilise each students strengths and skills in learning new concepts and reinfornce areas they may be weak in.`,
    topics: new Map([
        ["GCSE", [
            "Energy",
            "Electricity",
            "Particle Model",
            "Atomic Structure",
            "Forces",
            "Waves",
            "Magnetism and Electromagnetism",
            "Space",
            "Practicals and Data Analysis"
        ]]
    ]),
    school_level: [
            "GCSE - AQA, OCR, Pearson Edexcel"
        ],
    img_src: "/images/aldebaran-s-uXchDIKs4qI-unsplash.jpg",
    img_caption: `<p>Photo by <a href="https://unsplash.com/@aldebarans?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Aldebaran S</a> on <a href="https://unsplash.com/photos/purple-and-black-galaxy-illustration-uXchDIKs4qI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></p>`,
};

const SUBJECTS: SubjectMap = {
    comp_sci: COMPUTER_SCIENCE,
    maths: MATHS,
    biology: BIOLOGY,
    chemistry: CHEMISTRY,
    physics: PHYSICS
};

export { SUBJECTS };