import { Box, Button, Card, CardBody, CardFooter, CardHeader, Heading, Text, VStack, Link, useColorModeValue, } from "@chakra-ui/react";
import React from "react";
import SubjectAccordion from "../Components/SubjectAccordion";
import { Link as RouterLink } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { ANALYTICS } from "../util/Analytics";

function Home() {
    const heading = useColorModeValue('primary.main', 'white');
    const card = useColorModeValue('white', 'primary.800');

    return(
        <Box py={'1%'} px={'1%'} className="home">
            <VStack>
                <Card align={'center'} variant={'filled'} bg={card}>
                    <CardHeader>
                        <Heading size='2xl' textColor={heading}>The Foundations for Success</Heading>
                    </CardHeader>
                    <CardBody>
                        <Text fontSize={['sm', 'md', 'larger']}>
                            Here at A.T Tuition, we use a practical approach built by professional experience in Technology and Engineering industries to develop our students’ academic skills and insight into the real world application of the subjects we teach.
                            Whether it be Maths, Physics, or Computer Science, we have the right approach to ensure that our students succeed in whatever they want to achieve.
                            <br />
                            <br />
                            Primarily based in Harrow, North West London, we offer flexible tutoring over a range of platforms for our sessions including online, one-to-one, or in a classroom environment. We recognise that every student has a different learning style therefore we tailor our sessions to each student's needs. Our approaches include theory lessons, practical exercises, and practicising past exam papers.
                        </Text>
                    </CardBody>
                    <CardFooter>
                        <Link
                        rounded={'md'}
                        as={RouterLink}
                        to={"/contact_us"}
                        onClick={ () => logEvent(ANALYTICS, 'button_click', { value: 'book now' }) }>
                            <Button
                            _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'xl',
                            }} 
                            colorScheme={'tertiary'}>
                                <Text>Book a free consultation now!</Text>
                            </Button>
                        </Link>
                    </CardFooter>
                </Card>
                <SubjectAccordion/>
            </VStack>
        </Box>
    );
}

export default Home;