import { Box, TabList, TabPanel, TabPanels, Tabs, Tab, Center } from "@chakra-ui/react";
import React from "react";
import { Profile } from "../types/Profile"
import ProfileSection from "../Components/ProfileSection";
import { PROFILES } from '../resources/profiles'
import { logEvent } from "firebase/analytics";
import { ANALYTICS } from "../util/Analytics";

const profileList: Profile[] = PROFILES

function AboutUs() {
    
    return(
        <Box py={'4'} px={'2'} w={'100%'} className="about-us">
            <Center>
                <Tabs isFitted align="center" variant='soft-rounded' size={['sm', 'md', 'lg']} colorScheme='primary'>
                    <TabList px={'4'}>
                        <Tab textColor={'black'} 
                        onClick={() => logEvent(ANALYTICS, 'about_us_tab', { value: 'tasdiq' })}>
                            Tasdiq Dewan
                        </Tab>
                        <Tab textColor={'black'}
                        onClick={() => logEvent(ANALYTICS, 'about_us_tab', { value: 'abdul' })}>
                            Abdul-Jabbar Malik
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <ProfileSection {...profileList[0]}/>
                        </TabPanel>
                        <TabPanel>
                            <ProfileSection {...profileList[1]}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>            
            </Center>        
        </Box>
    );
}

export default AboutUs;