import AppHeader from './Components/AppHeader';
import NavBar from './Components/NavBar';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { Box, Button, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { SubjectMap } from './types/Subject';
import { SUBJECTS } from './resources/subjects'
import SubjectPage from './Pages/SubjectPage';
import React, { useEffect } from 'react';
import AppFooter from './Components/AppFooter';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import emailjs from "@emailjs/browser";

var subjectMap: SubjectMap = SUBJECTS;


function App() {
    useEffect(() => emailjs.init("ml9ReTFDjbReMsDtd"), []);
    const background = useColorModeValue('secondary.300', 'secondary.700');
    const { colorMode, toggleColorMode } = useColorMode();
    const toggleButton = useColorModeValue('tertiary.main', 'white')

	return (
    	<Box className="app" minH={'full'} w={'100%'} bg={background}>
			<Router>
                <AppHeader />
                <NavBar/>
                <Box bg={background} ms={"0%"} me={"0%"}>
                    <Routes>
                        <Route path="/" element={<Home/>} />
                        <Route path="/home" element={<Home/>} />
                        <Route path="/about_us" element={<AboutUs />} />
                        <Route path="/contact_us" element={<ContactUs />} />
                        <Route path="/subjects/computer_science" element={<SubjectPage {...subjectMap["comp_sci"]}/>} />
                        <Route path="/subjects/maths" element={<SubjectPage {...subjectMap["maths"]}/>} />
                        <Route path="/subjects/biology" element={<SubjectPage {...subjectMap["biology"]}/>} />
                        <Route path="/subjects/physics" element={<SubjectPage {...subjectMap["physics"]}/>} />
                        <Route path="/subjects/chemistry" element={<SubjectPage {...subjectMap["chemistry"]}/>} />
                    </Routes>
                </Box>
            </Router>
            <Box bottom={'0'}>
                <AppFooter />
            </Box>
            <Button 
            bg={toggleButton}
            justifySelf={'end'}
            onClick={toggleColorMode} 
            position={'fixed'} 
            bottom={'4'} 
            right={'4'}
            _hover={{}}>
                    {colorMode === 'light' 
                    ? <MoonIcon color={'white'} focusable={'true'} /> 
                    : <SunIcon color={'primary.main'} focusable={'true'} />}
            </Button>
    	</Box>
  	);
}

export default App;
