import ContactRequest from "../types/ContactRequest";
import emailjs from "@emailjs/browser";

async function submitEmailRequest(formData: ContactRequest) {
    const service_id = "at_contact_service";
    const template_id = "at_contact_form";

    emailjs.send(service_id, template_id, {
        name: formData.name,
        email: formData.email,
        phone_number: formData.phone_number,
        message: formData.message
    });
}

export default submitEmailRequest;