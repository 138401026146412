import { Box, Flex, Heading, Image, Link, useTheme } from "@chakra-ui/react";
import {Link as RouterLink } from "react-router-dom";
import React from "react";


function AppHeader() {
    const theme = useTheme();
    return(
        <Box bg={theme.colors.primary.main}>
            <Flex justifyContent={"center"}>
                <Link as={RouterLink} to={"/"}>
                    <Heading as="h1" fontSize="xxx-large" textAlign="center" >
                        <Image fallbackSrc='https://via.placeholder.com/' src={"../images/AT_Tuition_Banner.svg"} alt={"A.T Tuition"}  boxSize={"maxW"} objectFit={"fill"} />
                    </Heading>
                </Link>
            </Flex>
        </Box>
    );
}

export default AppHeader;