import { Profile } from "../types/Profile";

const TASDIQ_PROFILE: Profile = {
    name: "Tasdiq Dewan",
    img_src: "../images/tasdiq_dewan_profile_pic.jpg",
    experience: [
        "Junior Android Developer at NewDay Cards (via QA Consulting)",
        "Digital Archivist Trainee at Guardian News & Media Archive (via The National Archives)",
        "BSc Computer Science at Queen Mary University of London"
    ],
    description: `Tasdiq has over 6 years of experience with programming, having studied Computer Science at Queen Mary and currently working as a junior Android developer for NewDay Cards. He has extensive and practical knowledge of the Java and Kotlin programming languages as well as fundamentals in C#, C++, and JavaScript.
    \nTasdiq has taught and mentored students in academia across several key stage levels for an number of years, developing their foundational skills in preparation for secondary school and sixth form. Tasdiq aims to nurture students’ interest and understanding in the world of technology and computer science. His focus will be on developing students’ key skills to succeed in their studies as well as building the mindset of adaptability, flexibility, and self-driven learning and understanding that is the key to professional success in computer science.
    `,
    linkedIn: "https://www.linkedin.com/in/tasdiq-dewan/"
};

const ABDUL_PROFILE: Profile = {
    name: "Abdul-Jabbar Malik",
    img_src: "../images/abdul_jabbar_profile_pic.jpg",
    experience: [
        "Energy and Carbon Managar at British Film Institute",
        "Graduate Project Engineer at SSE plc",
        "Junior Commissioning Engineer at Kew Technology Ltd",
        "MSc Sustainable Energy Engineering at Queen Mary University of London",
        "BEng Mechanical Engineering at Queen Mary University of London"
    ],
    description: `Abdul-Jabbar Malik has 7 years teaching experience developing lesson plans and delivering tuition to a range of students through GCSE to A-Level. Having attained a Masters in Sustainable Engineering and a Bachelors in Mechanical Engineering from Queen Mary University of London, Abdul is currently leading the energy strategy for the British Film Institute. He has an extensive knowledge of mechanical systems and applied mathematics in STEM. This has been demonstrated through numerous roles within the industry.
    \nAbdul’s primary aim is to help committed students engage in academia with a view into the practical world of STEM whilst being able to secure a prosperous future in further education.
    `,
    linkedIn: "https://www.linkedin.com/in/abdul-jabbar-malik/"
};

const PROFILES: Profile[] = [TASDIQ_PROFILE, ABDUL_PROFILE];

export { TASDIQ_PROFILE, ABDUL_PROFILE, PROFILES };