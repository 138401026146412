import { Card, CardHeader, CardBody, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Text, Link, Button, useTheme, Heading, Spacer, useColorModeValue, Icon } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { MdOutlineScience } from 'react-icons/md';
import { BsFillFileEarmarkCodeFill } from 'react-icons/bs';
import { BiMath } from 'react-icons/bi'
import { Analytics, logEvent } from "firebase/analytics";
import { ANALYTICS } from "../util/Analytics";

const subjects = [
    {
        title: "Computer Science", 
        body: "We teach Computer Science covering the GCSE and A Level syllabus as well as strenghening core, transferable programming and problem solving skills.", 
        url: "/subjects/computer_science"
    },
    {
        title: "Mathematics",
        body: "We cover a range of topics in Mathematics from Key Stage 3 through to A Level, focusing on the fundamental skills for mathematical problem solving.",
        url: "/subjects/maths"
    },
    {
        title: "Biology",
        body: "We teach Biology up to GCSE, encouraging students to develop an appreciation for the subject from cell structures to intepreting enzyme experimental data.",
        url: "/subjects/biology"
    },
    {
        title: "Chemistry",
        body: "We teach Chemistry up to GCSE, covering key concepts in organic and inorganic chemistry from hydrocarbons to atomic structure.",
        url: "/subjects/chemistry"
    },
    {
        title: "Physics",
        body: "We teach Physics up to GCSE, helping students to understand the practical relationships between key concepts which is the key to excelling within the subject.",
        url: "/subjects/physics"
    }
]

function getIcon(name: string) {
    switch(name) {
        case "Computer Science":
            {return BsFillFileEarmarkCodeFill;}
        case "Mathematics":
           {return BiMath;}
        default: 
            {return MdOutlineScience;}
    }
}

export default function SubjectAccordion() {
    const theme = useTheme();
    const card = useColorModeValue('white', 'primary.800');
    const button = useColorModeValue('tertiary.main', 'tertiary.100');

    const NavLink = (page: {text: string, url: string}) => (
        <Link
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
            }}
            fontSize={'sm'}
            as={RouterLink}
            to={page.url}
            onClick={() => logEvent(ANALYTICS, 'button_click', {
                value: `find_out_more_${page.url}`
            })}
        >
            <Text>
                {page.text}
            </Text>
        </Link>
    );

    return(
        <Card w={'100%'}  variant={'filled'} bg={card} align={'center'}>
            <CardHeader>
                <Heading size={'lg'}>Below are the subjects that we specialise in. Click to find out more information.</Heading>
            </CardHeader>
            <CardBody>
                <Accordion allowMultiple width={['xs', 'sm', 'md', 'lg', '3xl']} rounded="lg">
                    {
                        subjects.map((prop, key: number) =>
                            (
                                <AccordionItem key={key}>
                                    <AccordionButton
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    p={4}
                                    _hover={{
                                        textDecoration: 'none',
                                        transform: 'translateY(-2px)',
                                        boxShadow: 'xl',
                                        textColor: 'black',
                                        bg: button
                                    }}
                                    rounded={'md'}>
                                        <Heading size={'md'}>
                                            <Icon as={getIcon(prop.title)}/>
                                            {prop.title}
                                        </Heading>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pb={4}>
                                        <Text>
                                            {prop.body}
                                        </Text>
                                        <Spacer h={'4'}/>
                                        <Button
                                        textColor={'white'}
                                        rounded={'md'}
                                        _hover={{
                                            transform: 'translateY(-2px)',
                                            boxShadow: 'xl',
                                            textDecoration: 'none',
                                            bg: theme.colors.tertiary.main
                                        }}
                                        bg={'secondary.main'}
                                        fontSize={'sm'}>
                                            <NavLink {...{text: "Find out more!", url: prop.url}}/>
                                        </Button>
                                    </AccordionPanel>
                                </AccordionItem>
                            ))
                    }
                </Accordion>        
            </CardBody>
        </Card>
    );
}