import { Box, Button, Card, CardHeader, Center, FormControl, FormErrorMessage, FormLabel, Heading, Input, InputGroup, InputLeftElement, Textarea, useColorModeValue, VStack } from "@chakra-ui/react";
import { MdOutlineEmail, MdPersonOutline, MdPhone } from 'react-icons/md';
import React, { useState } from "react";
import ContactRequest from "../types/ContactRequest";
import submitEmailRequest from "../util/EmailServices";
import { logEvent } from "firebase/analytics";
import { ANALYTICS } from "../util/Analytics";

function ContactForm() {
    const [ nameInput, setNameInput] = useState("");
    const [ nameError, setNameError ] = useState(false);
    const handleNameInputChange = (e) => {
        setNameInput(e.target.value);
    }

    const [ emailInput, setEmailInput ] = useState("");
    const [ emailError, setEmailError ] = useState(false);
    const handleEmailInputChange = (e) => {
        setEmailInput(e.target.value);
    }

    const [ phoneInput, setPhoneInput ] = useState("");
    const [ phoneError, setPhoneError ] = useState(false);
    const handlePhoneInputChange = (e) => {
        setPhoneInput(e.target.value);
    }

    const [ messageInput, setMessageInput ] = useState("");
    const [ messageError, setMessageError ] = useState(false);
    const handleMessageInputChange = (e) => {
        setMessageInput(e.target.value);
    }

    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    const handleSubmit = async (e) => {
       logEvent(ANALYTICS, 'button_click', { value: 'send message' })
       setEmailError(!isEmailValid(emailInput));
       setNameError(nameInput === "");
       setPhoneError(phoneInput === "");
       setMessageError(messageInput === "");
       if(!emailError && !nameError && !phoneError && !messageError) {
            e.preventDefault();
            try {
                setLoading(true);
                const formData: ContactRequest = {
                    name: nameInput,
                    email: emailInput,
                    phone_number: phoneInput,
                    message: messageInput
                };

                await submitEmailRequest(formData);
                logEvent(ANALYTICS, 'send_message', { value: 'success' })
                setSuccess(true);
            }
            catch (error) {
                console.log(error);
                logEvent(ANALYTICS, 'send_message', { value: 'failed' })
                setSuccess(false);
            }
            finally {
                setLoading(false);
            }
       }
    }

    const buttonHover = useColorModeValue('primary.main', 'tertiary.main');
    const successCard = useColorModeValue('tertiary.500', 'tertiary.200');

    if(!success) {
        return(
            <Box
                w={['sm', 'sm', 'md', 'lg', '2xl']}
                borderRadius="lg"
                p={8}
                shadow={'outline'}>
                <VStack spacing={'5'}>
                    <FormControl isRequired isInvalid={nameError}>
                        <FormLabel>Name</FormLabel>
                        <InputGroup>
                          <InputLeftElement>
                            <MdPersonOutline />
                          </InputLeftElement>
                          <Input type="text" name="name" placeholder="Your Name" 
                            value={nameInput} onChange={handleNameInputChange} />
                        </InputGroup>
                        <FormErrorMessage>Please enter your name.</FormErrorMessage>
                    </FormControl>
    
                    <FormControl isRequired isInvalid={emailError}>
                        <FormLabel>Email</FormLabel>
                        <InputGroup>
                          <InputLeftElement>
                            <MdOutlineEmail />
                          </InputLeftElement>
                          <Input type={"email"} name={"email"} placeholder={"Your Email"} 
                            value={emailInput} onChange={handleEmailInputChange} />
                        </InputGroup>
                        <FormErrorMessage>Invalid email address.</FormErrorMessage>
                    </FormControl>
    
                    <FormControl isRequired isInvalid={phoneError}>
                        <FormLabel>Phone Number</FormLabel>
                        <InputGroup>
                            <InputLeftElement><MdPhone /></InputLeftElement>
                            <Input type="tel" name={"phone_number"} placeholder={"Your Phone Number"} 
                                value={phoneInput} onChange={handlePhoneInputChange} />
                        </InputGroup>
                        <FormErrorMessage>Please enter your phone number.</FormErrorMessage>
                    </FormControl>
    
                    <FormControl isRequired isInvalid={messageError}>
                        <FormLabel>Message</FormLabel>
                        <Textarea
                          name={"message"}
                          placeholder={"Please leave a message with your enquiry about AT Tuition and we'll contact you soon to discuss more."}
                          rows={6}
                          resize={"none"}
                          value={messageInput}
                          onChange={handleMessageInputChange}
                        />
                        <FormErrorMessage>Please enter your message.</FormErrorMessage>
                    </FormControl>
    
                    <Button
                        disabled={loading}
                        colorScheme="tertiary"
                        color="white"
                        _hover={{
                          bg: buttonHover,
                        }}
                        width="full"
                        onClick={handleSubmit}>
                        Send Message
                    </Button>
                </VStack>
            </Box>
        );
    } else {
        return(
            <Center w={['sm', 'sm', 'md', 'lg', '2xl']}
            borderRadius="lg"
            p={8}
            shadow={'outline'}>
                <Card bg={successCard} >
                    <CardHeader><Heading>Message sent successfully!</Heading></CardHeader>
                </Card>
            </Center>
        );
    }
}

function isEmailValid(email: string): boolean {
    const expression: RegExp = new RegExp(/^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/);
    return expression.test(email)
}

export default ContactForm;
