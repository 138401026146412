import { extendTheme } from '@chakra-ui/react'

const colours = {
    primary: {
        main: '#023476',
        50: '#e0f2ff',
        100: '#b2d4ff',
        200: '#82b7fd',
        300: '#519afb',
        400: '#267df9',
        500: '#1363e0',
        600: '#094daf',
        700: '#02377d',
        800: '#00214d',
        900: '#000b1e',
    },
    secondary: {
        main: '#282c34',
        50: '#eef2fa',
        100: '#d4d7de',
        200: '#b8bcc5',
        300: '#9ca2ad',
        400: '#808796',
        500: '#676e7d',
        600: '#505562',
        700: '#383d46',
        800: '#21252b',
        900: '#080c13',
    },
    tertiary: {
        main: '#246ccb',
        50: '#e1f2ff',
        100: '#bad5f8',
        200: '#91b9ef',
        300: '#659de5',
        400: '#3c81dc',
        500: '#2368c3',
        600: '#185199',
        700: '#0d3a6e',
        800: '#032345',
        900: '#000d1d',
    },
}

const theme = extendTheme(
    {
        config: {
            initialColorMode: 'system',
            useSystemColorMode: true
        },
        colors: {
            primary: {
                main: '#023476',
                50: '#e0f2ff',
                100: '#b2d4ff',
                200: '#82b7fd',
                300: '#519afb',
                400: '#267df9',
                500: '#1363e0',
                600: '#094daf',
                700: '#02377d',
                800: '#00214d',
                900: '#000b1e',
            },
            secondary: {
                main: '#282c34',
                50: '#eef2fa',
                100: '#d4d7de',
                200: '#b8bcc5',
                300: '#9ca2ad',
                400: '#808796',
                500: '#676e7d',
                600: '#505562',
                700: '#383d46',
                800: '#21252b',
                900: '#080c13',
            },
            tertiary: {
                main: '#246ccb',
                50: '#e1f2ff',
                100: '#bad5f8',
                200: '#91b9ef',
                300: '#659de5',
                400: '#3c81dc',
                500: '#2368c3',
                600: '#185199',
                700: '#0d3a6e',
                800: '#032345',
                900: '#000d1d',
            },
        },
        components: {
            Link: {
                defaultProps: {
                    bg: colours.secondary.main,
                },
            },
            MenuButton: {
                defaultProps: {
                    bg: colours.secondary.main,
                },
            },
            MenuList: {
                defaultProps: {
                    bg: colours.secondary.main,
                },
            },
            MenuItem: {
                defaultProps: {
                    bg: colours.secondary.main,
                },
            }   
        },
    },
);

export default theme;