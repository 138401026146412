'use client'

import { SiLinkedin } from 'react-icons/si'
import { Button, Center, Text, Link } from '@chakra-ui/react'
import React from 'react'

function LinkedinButton(props: {url: string}) {
  return (
    <Center p={8}>
      <Link href={props.url} isExternal>
        <Button w={'full'} maxW={'md'} colorScheme={'linkedin'} leftIcon={<SiLinkedin />}>
            <Center>
            <Text>View LinkedIn profile</Text>
            </Center>
        </Button>
      </Link>
    </Center>
  )
}

export default LinkedinButton