import {
    Box,
    Container,
    Stack,
    Text,
    Image
  } from '@chakra-ui/react';
import React from 'react';

export default function AppFooter() {
    return (
      <Box bg={'secondary.main'}>
        <Container
          as={Stack}
          maxW={'xl'}
          py={4}
          direction={{ base: 'row' }}
          spacing={1}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <Image height={'50px'} fallbackSrc='https://via.placeholder.com/' src={"../images/AT_Logo_White.svg"} alt={"A.T Tuition"} />
          <Text textColor={'white'}>© 2023 A.T Tuition. All rights reserved</Text>
          <Text textColor={'white'}>9 Trescoe Gardens<br/>Harrow<br/>London<br/>HA2 9TB</Text>
        </Container>
      </Box>
    )
  }
