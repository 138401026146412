import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { Button, Flex, HStack, Link, Menu, MenuButton, MenuItem, MenuList, Text, useColorMode, useColorModeValue, useTheme } from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import type { Page } from "../types/Page";
import { ANALYTICS } from "../util/Analytics"

const home: Page = {
    name: "Home",
    url: "/home"
};

const aboutUs: Page = {
    name: "About Us",
    url: "/about_us"
};

const contactUs: Page = {
    name: "Contact Us",
    url: "/contact_us"
};

const computerScience: Page = {
    name: "Computer Science",
    url: "/subjects/computer_science"
};

const maths: Page = {
    name: "Mathematics",
    url: "/subjects/maths"
};

const biology: Page = {
    name: "Biology",
    url: "/subjects/biology"
};

const physics: Page = {
    name: "Physics",
    url: "/subjects/physics"
};

const chemistry: Page = {
    name: "Chemistry",
    url: "/subjects/chemistry"
};

const PAGES: Page[] = [home, aboutUs];
const SUBJECTS: Page[] = [computerScience, maths, biology, chemistry, physics]; 


function NavBar() {
    const theme = useTheme();
    const { colorMode, toggleColorMode } = useColorMode();
    const toggleButton = useColorModeValue('tertiary.main', 'white')

    const NavLink = (page: Page) => (
        <Link
            px={5}
            py={2}
            h={'fit-content'}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                bg: theme.colors.tertiary.main
            }}
            fontSize={['sm', 'md', '2xl']}
            as={RouterLink}
            to={page.url}
            onClick={ () => logEvent(ANALYTICS, 'navigate', 
                {
                    value: page.name
                }
            )}>
            <Text textColor={"white"}>
                {page.name}
            </Text>
        </Link>
    );

    const SubjectLink = (page: Page) => (
        <Link
            px={4}
            py={1}
            h={'fit-content'}
            w={'full'}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                bg: theme.colors.tertiary.main
            }}
            fontSize={['sm', 'md', '2xl']}
            as={RouterLink}
            to={page.url}
            onClick={ () => logEvent(ANALYTICS, 'navigate', 
                {
                    value: page.name
                }
            )}>
            <Text textColor={"white"} textAlign={'center'}>
                {page.name}
            </Text>
        </Link>
    );

    return(
            <HStack justify={'center'} bg={'secondary.main'} borderTop={'ActiveBorder'} borderTopStyle={'outset'} minW={'100%'} h={'fit-content'}>
                <Flex h={'fit-content'} align={'center'} justify={'center'} direction={"row"} justifyItems={'space-evenly'}>
                    {
                        PAGES.map((page: Page, key: number) =>
                            (
                                <NavLink key={key} {...page}/>
                            )
                        )
                    }
                    <Menu>
                        <MenuButton 
                            as={Text}
                            textColor={'white'}
                            h={'fit-content'}
                            px={5}
                            py={2}
                            rounded={'md'}
                            _hover={{
                                textDecoration: 'none',
                                bg: theme.colors.tertiary.main
                            }}
                            fontSize={['sm', 'md', '2xl']}>
                                Subjects
                        </MenuButton>
                        <MenuList bg={'secondary.main'}>
                            {
                                SUBJECTS.map((subject: Page, key: number) =>
                                    (
                                        <MenuItem bg={theme.colors.secondary.main}>
                                            <SubjectLink key={key} {...subject}/>
                                        </MenuItem>
                                    )
                                )
                            }
                        </MenuList>
                    </Menu>
                    <NavLink key={3} {...contactUs}/>
                </Flex>
                <Button 
                bg={toggleButton}
                onClick={toggleColorMode}
                _hover={{}}>
                    {colorMode === 'light' 
                    ? <MoonIcon color={'white'} focusable={'true'} /> 
                    : <SunIcon color={'primary.main'} focusable={'true'} />}
                </Button>
            </HStack>
    );
}

export default NavBar;