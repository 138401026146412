// Import the functions you need from the SDKs you need
import { initializeApp, } from "firebase/app";
import { getAnalytics, } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA29dKW6Ycz4LnuUoGohRMH328o7u63hjQ",
  authDomain: "at-tuition.firebaseapp.com",
  projectId: "at-tuition",
  storageBucket: "at-tuition.appspot.com",
  messagingSenderId: "470716699023",
  appId: "1:470716699023:web:22975623e33dddb193bbfa",
  measurementId: "G-M2QVM07S4W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const ANALYTICS = getAnalytics(app);